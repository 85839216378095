@charset "UTF-8";

.article-card-lists {
  align-items: top;
  -webkit-column-gap: 20px;
  column-gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 276px);
  justify-content: center;
  justify-items: center;
  row-gap: 30px;
}

.article-card-lists a {
  display: block;
  cursor: pointer;
  text-decoration: none;
}

.article-card-lists .card {
  height: auto;
  width: 100%;
}

.article-card-lists .card:hover {
  opacity: 0.8;
}

.article-card-lists .card img {
  aspect-ratio: 1/0.56;
  display: block;
  margin-bottom: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.article-card-lists .card .title {
  word-wrap: break-word;
  color: #121212;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.6;
}

@media (max-width: 560px) {
  .article-card-lists {
    align-items: top;
    -webkit-column-gap: 10px;
    column-gap: 10px;
    grid-template-columns: calc(50vw - 15px) calc(50vw - 15px);
    justify-items: center;
    row-gap: 20px;
  }

  .article-card-lists .card img {
    display: block;
    width: calc(50vw - 15px);
    height: calc((50vw - 15px) * 9 / 16);
    margin-bottom: 10px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .article-card-lists .card .title {
    font-size: 1rem;
    line-height: 1.4;
  }
}

html {
  font-size: 16px;
}

a,
div,
p {
  font-size: 1rem;
  word-break: break-word;
}

a {
  text-decoration: none;
}

main {
  font-family: sans-serif;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

main a:hover {
  opacity: 0.8;
}

main .container {
  -webkit-column-gap: 30px;
  column-gap: 30px;
  display: grid;
  grid-template-columns: minmax(900px, 1fr) 300px;
}

main .container .sub h1.side-headding {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family:
    Roboto Condensed,
    sans-serif;
  justify-content: center;
  text-align: center;
}

main .container .sub h1.side-headding .headding-main {
  border-bottom: none;
  display: inline-block;
  font-size: 24px;
  letter-spacing: 1px;
  padding-bottom: 0;
}

main .container .sub h1.side-headding .headding-sub {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin-top: -5px;
}

@media (max-width: 560px) {
  main {
    max-width: 100%;
    padding: 0 18px;
  }

  main .container {
    display: block;
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.article {
  padding: 60px 10px 90px;
}

.article .article-meta {
  border-bottom: 1px solid #121212;
  display: grid;
  grid-template-columns: 1fr 160px;
  grid-template-rows: 1fr auto;
  margin-bottom: 30px;
  padding-bottom: 16px;
  row-gap: 20px;
}

.article .article-meta h1 {
  font-size: 28px;
  font-weight: 700;
  grid-column: 1/3;
  grid-row: 1/2;
  line-height: 1.4;
}

.article .article-meta .tags {
  grid-column: 1/2;
  grid-row: 2/3;
  text-align: left;
}

.article .article-meta .tags a {
  border: 1px solid #666;
  border-radius: 12px;
  color: #666;
  display: inline-block;
  font-size: 11px;
  margin-right: 10px;
  padding: 0 5px;
}

.article .article-meta time {
  color: #ad154f;
  font-size: 14px;
  text-align: right;
}

.article .body {
  border-bottom: 1px solid #121212;
  margin-bottom: 50px;
  padding-bottom: 20px;
}

.article .body #mokuji {
  margin-bottom: 30px;
  padding: 30px;
  background-color: #efefef;
}

.article .body #mokuji h2 {
  margin: 0 0 30px 0;
  padding: 0;
  font-size: 20px;
  border-bottom: none;
}

.article .body #mokuji h2::after {
  background: none;
}

.article .body #mokuji ul {
  margin: 0;
  list-style: circle;
}

.article .body #mokuji ul#root {
  padding: 0 30px;
}

.article .body #mokuji ul a {
  color: #ad154f;
}

.article .body #mokuji ul li {
  line-height: 2;
}

.article .body #mokuji ul li.mokuji-1 {
  margin-left: 30px;
}

.article .body #mokuji ul li.mokuji-2 {
  margin-left: 60px;
}

.article .body #mokuji .list-none {
  margin-left: 20px;
  list-style: none;
}

.article .body a.eijiro-link {
  color: inherit;
  text-decoration-style: dotted;
  text-underline-offset: 5px;
  text-decoration-color: #939393;
  white-space: nowrap;
}

.article .body ul {
  list-style: disc;
  margin: 0 0 30px 30px;
  padding: 0;
}

.article .body ol {
  list-style: decimal;
  margin: 0 0 30px 30px;
  padding: 0;
}

.article .body li {
  margin-bottom: 5px;
}

.article .body blockquote > ol {
  margin-bottom: 0;
}

.article .body blockquote > ul {
  margin-bottom: 0;
}

.article .body .tableScroll {
  margin-bottom: 30px;
  overflow-x: auto;
}

.article .body .tableScroll table {
  width: 100%;
  white-space: nowrap;
}

.article .body .tableScroll table thead tr th,
.article .body .tableScroll table tbody tr td {
  padding: 10px 15px;
  border: 1px solid #dfdfdf;
}

.article .body .tableScroll table thead {
  background-color: #efefef;
}

.article .body .tableWrap {
  margin-bottom: 30px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.article .body .tableContents {
  margin-bottom: 30px;
  padding: 30px 20px 20px;
  font-size: 20px;
  font-weight: bold;
  background-color: #ececec;
}

.article .body .tableContents ul.table-of-contents {
  padding-top: 20px;
  font-weight: normal;
  border-top: 1px solid #555555;
}

.article .body .tableContents ul.table-of-contents li {
  margin: 20px;
  font-size: 16px;
}

.article .body .tableContents ul.table-of-contents li a {
  color: #ad154f;
  text-decoration: underline;
}

.article .body .tableContents ul.table-of-contents li ul li::before {
  content: '-';
}

.article .body .tableContents ul.table-of-contents li ul li {
  font-size: 14px;
}

.article .body img.thumbnail {
  display: block;
  margin-bottom: 10px;
  width: 100%;
  height: 480px;
  object-fit: cover;
}

.article .body .description {
  font-size: 16px;
  margin-bottom: 30px;
  padding: 10px;
}

.article .body .interview {
  position: relative;
  display: grid;
  grid-template-columns: 70px 1fr;
  column-gap: 20px;
  margin-bottom: 30px;
}

.article .body .interview-left::before {
  content: '';
  position: absolute;
  top: 45px;
  left: 70px;
  margin-top: -15px;
  border: 10px solid transparent;
  border-right: 15px solid #ececec;
}

.article .body .interview figure.thumb {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
}

.article .body .interview figure.thumb img {
  max-width: 100%;
  height: auto;
}

.article .body .interview .interviewInner {
  width: 100%;
  padding: 20px;
  background-color: #ececec;
  border-radius: 10px;
}

.article .body .interview .interviewInner p {
  margin: 0 0 30px;
  font-size: 15px;
}

.article .body h2 {
  border-bottom: 2px solid #121212;
  color: #121212;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.3;
  margin: 0 0 30px;
  padding: 16px;
  position: relative;
}

.article .body h2:after {
  background: #ad154f;
  bottom: -2px;
  content: '';
  height: 2px;
  left: 0;
  position: absolute;
  width: 120px;
}

.article .body h3 {
  border-left: 2px solid #ad154f;
  font-size: 24px;
  padding-left: 18px;
}

.article .body h3,
.article .body h4 {
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 30px;
}

.article .body h4 {
  font-size: 22px;
}

.article .body h4:before {
  background: #ad154f;
  content: '';
  display: inline-block;
  height: 2px;
  margin: -0.2em 12px 0 0;
  vertical-align: middle;
  width: 12px;
}

.article .body h5 {
  font-size: 20px;
}

.article .body h5,
.article .body h6 {
  color: #121212;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 30px;
}

.article .body h6 {
  font-size: 16px;
}

.article .body a {
  font-size: inherit;
}

.article .body p {
  color: #121212;
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 30px;
}

.article p.lead {
  padding: 20px;
  font-size: 16px;
  border: 1px solid #121212;
  position: relative;
}

.article p.lead:before {
  border: 1px solid #121212;
  bottom: 0;
  content: '';
  display: block;
  height: calc(100% - 8px);
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 8px);
  z-index: -1;
}

.article p.caption {
  font-size: 12px !important;
  color: #555555 !important;
  text-align: right;
}

.article .body p a {
  color: #ad154f;
  text-decoration: underline;
  word-break: normal;
}

.article .body blockquote p a {
  word-break: break-word;
}

.article .body blockquote {
  background: #f4f4f4;
  color: #121212;
  line-height: 1.8;
  margin-bottom: 30px;
  padding: 30px;
  position: relative;
  border-left: 2px solid #121212;
}

.article .body blockquote p:last-child {
  margin: 0;
}

.article .body img {
  height: auto;
  margin: 0 auto 30px;
  max-width: 96%;
}

.article .body figure {
  margin-bottom: 30px;
  padding: 0 2%;
}

.article .body figure img {
  max-width: 100%;
  height: auto;
  margin: 0 auto 10px;
}

.article .body figure figcaption {
  font-size: 12px;
  color: #555555;
  text-align: right;
}

.article .body figure + .caption {
  margin-top: -20px;
}

.article .body .youtube {
  position: relative;
  width: 100%;
  margin: 30px auto;
}

.article .body .youtube::before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 56.25%;
}

.article .body .youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.article .body .youtube + .caption {
  margin-top: -20px;
}

.article .body .twitter-tweet {
  max-width: none !important;
  justify-content: center;
}

.article .body .twitter-tweet iframe {
  flex-grow: 0 !important;
  width: 50% !important;
}

.article .body iframe.instagram-media {
  margin: 0 auto !important;
}

@media (max-width: 560px) {
  .article .body .twitter-tweet {
    max-width: 550px !important;
  }

  .article .body .twitter-tweet iframe {
    flex-grow: 1 !important;
    width: 370px !important;
  }

  .article .body iframe.instagram-media {
    margin: 0px 0px 12px !important;
  }
}

.article .body a.internal-article-link {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  -webkit-column-gap: 20px;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 144px 1fr;
  grid-template-rows: 81px;
  margin-bottom: 30px;
  padding: 20px;
}

.article .body a.internal-article-link img {
  display: block;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.article .body a.internal-article-link .info {
  position: relative;
}

.article .body a.internal-article-link .info .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
}

.article .body a.internal-article-link .info time {
  bottom: 0;
  font-size: 12px;
  position: absolute;
  right: 10px;
}

.article .body a[href^="booco://"]
{
  display: none;
}

summary {
  display: list-item;
  margin-bottom: 30px;
}

.article .body .product-link {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  -webkit-column-gap: 20px;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 200px 1fr;
  margin-bottom: 30px;
  padding: 20px;
}

.article .body .product-link img {
  display: block;
  width: 100%;
  height: 150px;
  margin: 0;
  object-fit: contain;
}

.article .body .product-link .info .title {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 10px;
  overflow: hidden;
}

.article .body .product-link .info .manufacturer,
.article .body .product-link .info .note {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #727272;
  display: -webkit-box;
  font-size: 14px;
  overflow: hidden;
  margin-bottom: 20px;
}

.article .body .product-link .info button {
  background-color: rgba(25, 48, 70, 0.122);
  border-radius: 2px;
  display: block;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 20px;
  text-align: center;
  width: auto;
}

.article .body .author {
  -webkit-column-gap: 16px;
  column-gap: 16px;
  display: grid;
  grid-template-columns: 160px 1fr;
  margin-bottom: 30px;
  padding: 16px;
}

.article .body .author img {
  border-radius: 50%;
  height: 160px;
  margin: 0;
  width: 160px;
  object-fit: cover;
}

.article .body .author .author-name {
  color: #121212;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.article .body .author p {
  color: #121212;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

.article .border-double {
  border: 1px solid #121212;
  position: relative;
}

.article .border-double:before {
  border: 1px solid #121212;
  bottom: 0;
  content: '';
  display: block;
  height: calc(100% - 8px);
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 8px);
  z-index: -1;
}

.article h1.menu {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family:
    Roboto Condensed,
    sans-serif;
  justify-content: center;
  text-align: center;
}

.article h1.menu span.headding-main {
  border-bottom: 2px solid #ad154f;
  display: inline-block;
  font-size: 32px;
  line-height: 1.1;
}

.article h1.menu span.headding-sub {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  padding-top: 5px;
}

.article .btn {
  text-align: center;
}

.article .btn a {
  border: 2px solid #ad154f;
  display: inline-block;
  font-family:
    Roboto Condensed,
    sans-serif;
  font-size: 20px;
  padding: 15px 0;
  width: 260px;
}

.article .btn a:hover {
  background-color: #ad154f;
  color: #fff;
}

@media (max-width: 560px) {
  .article {
    padding: 30px 10px 100px;
  }

  .article .article-meta {
    grid-template-columns: 1fr 100px;
    grid-template-rows: 1fr auto;
    row-gap: 10px;
  }

  .article .article-meta h1 {
    font-size: 24px;
    line-height: 1.2;
  }

  .article .article-meta .tags a {
    border: 1px solid #666;
    border-radius: 12px;
    color: #666;
    display: inline-block;
    font-size: 11px;
    margin-right: 0px;
    padding: 0 5px;
  }

  .article .article-meta time {
    color: #ad154f;
    font-size: 14px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
  }

  .article .body h2,
  .article .body h3 {
    font-size: 20px;
  }

  .article .body h4,
  .article .body h5 {
    font-size: 18px;
  }

  .article .body img.thumbnail {
    height: 200px;
    margin-bottom: 20px;
  }

  .article .body #mokuji {
    padding: 16px;
  }

  .article .body #mokuji h2 {
    font-size: 18px;
  }

  .article .body #mokuji ul#root {
    padding: 0 20px;
  }

  .article .body #mokuji ul li {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 14px;
  }

  .article .body #mokuji .list-none {
    margin-left: 16px;
    list-style: none;
  }

  .article .body a.internal-article-link {
    -webkit-column-gap: 16px;
    column-gap: 16px;
    grid-row: 68px;
    grid-template-columns: 120px 1fr;
    padding: 10px;
  }

  .article .body a.internal-article-link .info {
    position: relative;
  }

  .article .body a.internal-article-link .info .title {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden;
  }

  .article .body a.internal-article-link .info time {
    bottom: 0;
    font-size: 11px;
    left: 0;
  }

  .article .body .product-link {
    display: grid;
    grid-template-columns: 150px 1fr;
    padding: 10px;
  }

  .article .body .product-link .info .title {
    font-size: 14px;
    line-height: 1.4;
  }

  .article .body .product-link .info .manufacturer,
  .article .body .product-link .info .note {
    font-size: 12px;
  }

  .article .body .author {
    -webkit-column-gap: 16px;
    column-gap: 0;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 30px;
    padding: 16px;
  }

  .article .body .author img {
    border-radius: 50%;
    height: 100px;
    margin: 0 auto 20px;
    width: 100px;
    object-fit: cover;
  }

  .article .body .author .author-name {
    color: #121212;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: center;
  }

  .article .body .author p {
    color: #121212;
    font-size: 15px;
    line-height: 1.7;
    margin: 0;
    padding: 0 10px 10px;
  }
}
